
.Scrubber-Panel-Seek-Button-Container {
    height: 100%;
    z-index: 1;
}

.Scrubber-Panel-Seek-Button {
    background: #88d7f7;
    border: 1px solid #000;
    border-top: 0;
    border-bottom: 0;
    color: #fff;
    cursor: pointer;
    height: 100%;
    width: 80px;
}

.Scrubber-Panel-Seek-Button-Back {
    border-left: 0;
}

.Scrubber-Panel-Seek-Button-Forward {
    border-right: 0;
    float: right;
}