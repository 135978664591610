
.Admin-Users-Container {
    height: calc(100vh - 28px);
    overflow: hidden;
    padding: 64px 24px 0 24px;
    width: 100%;
}

.Admin-Users-Wrapper {
    height: 100%;
    overflow: auto;
    position: relative;
    width: 100%;

    -ms-overflow-style: none;
    scrollbar-width: none;
}
.Admin-Users-Wrapper::-webkit-scrollbar {
    display: none;
}