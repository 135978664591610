
.Scrubber-Scrubber-Panel-Container {
    border: 1px solid #0b56af;
    border-radius: 4px;
    width: 100%;
}

.Scrubber-Scrubber-Panel-Wrapper {
    background-color: #fff;
    border: 1px solid #bfbfbf;
    border-radius: 4px;
    display: flex;
    height: 120px;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.Scrubber-Scrubber-EPG-Button {
    background: #085877;
    border: 2px solid blue;
    border-radius: 6px;
    box-shadow: 3px 3px 3px black;
    color: #fff;
    cursor: pointer;
    margin-left: 12px;
    margin-top: 2px;
    position: absolute;
    width: 52px;
}