
.Scrubber-Panel-Timeline-Container {
    display: flex;
    width: 100%;
}

.Scrubber-Panel-Timeline-Segment-Container {
    border-right: 1px solid #000;
    height: 25%;
    width: 20%;
}

.Scrubber-Panel-Timeline-Segment-Container:nth-child(2) {
    border-right: 4px solid #ff0000;
    height: 100%;
}

.Scrubber-Panel-Timeline-Segment-Container:nth-child(7) {
    height: 50%;
}

.Scrubber-Panel-Timeline-Segment-Container:nth-child(12) {
    height: 50%;
}

.Scrubber-Panel-Timeline-Segment-Container:nth-child(17) {
    height: 50%;
}

.Scrubber-Panel-Timeline-Segment-Container:nth-child(22) {
    height: 50%;
}

.Scrubber-Panel-Timeline-Segment-Container:nth-child(27) {
    height: 50%;
}

.Scrubber-Panel-Timeline-Segment-Container:last-child {
    border-right: 0;
}

.Scrubber-Panel-Timeline-Segment {
    background: #fff;
    color: #000;
    transform: translate(-27px, 16px)
}

.Scrubber-Panel-Timeline-Audio-Only-Bar {
    background: #df8080;
    height: 18px;
    position: absolute;
    top: 28%;
    width: 100%;
}