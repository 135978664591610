
.HLS-Player-Main {
    display: flex;
}

.HLS-Player-Container {
    position: relative;
    width: 100%;
}

.HLS-Player-Overlay {
    aspect-ratio: 16/9;
    background: #000;
    display: flex;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    & img {
        margin: auto;
    }
}

.HLS-Player-Overlay-error {
    aspect-ratio: 16/9;
    background: rgba(0, 0, 0, 0.75);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    padding: 50px;

    color: rgb(255, 125, 125);
    text-align: center;
    font-size: 24px;

    .evo-btn {
        margin-top: 20px;
    }
}

.HLS-Player {
    aspect-ratio: 16 / 9;
    background: #000;
}