  // Core
@import "black-react/custom/functions";
@import "black-react/bootstrap/functions";
@import 'black-react/custom/variables';
  
  body {
    margin: 0;
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
  }

  hr {
    border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
  }

  .main-panel{
    background: linear-gradient(225deg, $background1, transparent, $background2, transparent, $background3, transparent, $background4) !important;

    background-size: 800% 800% !important;
    // Background Anim
    -webkit-animation: BackgroundGradientAnimMain 60s ease infinite !important;
    -moz-animation: BackgroundGradientAnimMain 60s ease infinite !important;
    animation: BackgroundGradientAnimMain 60s ease infinite !important;

    overflow: hidden;
  }

  .tim-row {
    margin-bottom: 20px;
  }
  
  .tim-white-buttons {
    background-color: #777777;
  }
  
  .typography-line {
    padding-left: 25%;
    margin-bottom: 35px;
    position: relative;
    display: block;
    width: 100%;
  }
  
  .typography-line span {
    color: #c0c1c2;
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    left: 0;
    position: absolute;
    width: 260px;
    text-transform: none;
  }
  
  .tim-row {
    padding-top: 60px;
  }
  
  .tim-row h3 {
    margin-top: 0;
  }
  
  .offline-doc .page-header:before {
    content: "";
    background: rgba(0, 0, 0, .75);
  }
  
  .offline-doc .page-header {
    display: flex;
    align-items: center;
  }
  
  .offline-doc .footer {
    position: absolute;
    width: 100%;
    background: transparent;
    bottom: 0;
    color: #fff;
    z-index: 1;
  }
  
  #map {
    position: relative;
    width: 100%;
    height: 100vh;
  }

  .errorText{
    color: $danger;
  }

  .loginLogo{
      float: left;
      width: 200px;
  }

  .spacer{
      margin-bottom: 50px;
  }

  .loginForm{
      transition: all 750ms ease-in;
  }

  .cardHidden {
    opacity: 0;
    transform: translate3d(0, -60px, 0);
  }

  .modal-backdrop{
    background: rgba(0, 0, 0, 0.7);
  }
  .modal-backdrop.show{
    opacity: 1;
  }

  .ingestModal{
    max-width: auto !important;
    width: 60vw;

    .modal-content{
        background-color: $background-states-black !important;
        .modal-header{
            .tim-icons {
                font-size: 20px;
            }
            .close{
                color: $white;
                top: 0px !important;
            }
            .title{
                color: $white;
                font-weight: 200;
                font-size: 22px;
            }
        }
    }
    // .modal-body {
    //     .content {
            
    //     }
    // }

    -webkit-animation-duration: 1s !important;
    animation-duration: 1s !important;
    -webkit-animation-fill-mode: both !important;
    animation-fill-mode: both !important;
    -webkit-animation-name: show !important;
    animation-name: show !important;
  }

  .actionBtnContainer{
    float: right;
    margin: 20px 0px 0px;
  }

  .ReactModalPortal{
    z-index: 1030;
  }
  .deleteDialog{
    z-index: 1080;
    div > .modal-content{
      background-color: $background-states-black !important;
      .modal-header{
        .modal-title{
          color: $white;
        }
      }
    }
  }
  .ReactModal__Overlay{
    z-index: 1040;
  }

  .progressDisplay{
    height: 30px;
    padding: 10px;

    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    margin: 15px 10px;

    .progress{
      background-color: rgba(255, 163, 0, 0.75) !important;
      box-shadow: 0px 0px 10px 2px rgba(255, 163, 0, 0.3) !important;
    }
  }

  .ingestImages, .ingestTags, .ingestAdditional{
    margin-top: 20px;
  }

  .card-wizard{
    .card-footer{
      display: none !important;
    }
  }

  .uppy-DragDrop-inner{
    color: $white;
  }

  input.uppy-DragDrop-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .uppy-DragDrop-note, .UppyIcon{
    display: none;
  }

  .nav {
    li.active{
      background-color: $primaryTrans75;
    }
  }

  .totalPlaysTableHead{
    margin: 0px !important;
  }
  .totalPlaysTable{
    max-height: 400px;
    overflow-y: scroll;

    .loadTime{
      float: right;
    }
  }

  .hand{
      cursor: pointer !important;
  }

  .sml{
      font-size: 9px;
  }

.demoColor1{
  background-color: $success;
  min-height: 100px;
}
.demoColor2{
  background-color: $info;
  min-height: 100px;
}
.demoColor3{
  background-color: $warning;
  min-height: 100px;
}
.demoColor4{
  background-color: $danger;
  min-height: 100px;
}

.ContainerDiv{
  height: 100%;
  /* like display:flex but will allow bleeding over the window width */
  width: 100%;
  /* width: '80%'; */
  display: inline-flex;
}

.EmptyChannelContainerDiv{
  text-align: center;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
}

@media (min-width: 1200px) {
    .modal-dialog {
        max-width: 800px;
        margin: 10vh auto
    }
}

// Main Background

@-webkit-keyframes BackgroundGradientAnimMain {
  0%{background-position:0% 50%}
  50%{background-position:100% 51%}
  100%{background-position:0% 50%}
}
@-moz-keyframes BackgroundGradientAnimMain {
  0%{background-position:0% 50%}
  50%{background-position:100% 51%}
  100%{background-position:0% 50%}
}
@keyframes BackgroundGradientAnimMain { 
  0%{background-position:0% 50%}
  50%{background-position:100% 51%}
  100%{background-position:0% 50%}
}