.fileContainer {
  overflow: hidden;
  position: relative;
  color: #009cbd;
  margin-top: 5px;
  border: 1px solid #009cbd;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
}

.fileContainer:hover {
  cursor: pointer;
  background-color: #009cbd;
  color: #ffffff;
}

.fileContainer [type=file] {
  cursor: inherit;
  display: block;
  //font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}
.editorControls{

  > button {
    margin-right: 5px;
    margin-top: 5px;
  }
}
.imgContainer{
  //background-color: #4a4a4a;
  border: 1px solid #2b3553;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 10px;
}
.imagesContainer{
  //width: 300px;
  text-align: center;
  margin: 0 auto;
}

body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #474747;
}

body::-webkit-scrollbar-track-piece{
  background-color: #474747;
}

body::-webkit-scrollbar-thumb {
  background-color: #474747;
  outline: 1px solid rgb(66, 66, 66);
  border-radius: 5px;
}

// Slider
// Hide Original
input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}

// New styling
/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #000000;
  height: 28px;
  width: 16px;
  border-radius: 3px;
  background: #c6f5ff;
  cursor: pointer;
  margin-top: -10px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 28px;
  width: 16px;
  border-radius: 3px;
  background: #c6f5ff;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 28px;
  width: 16px;
  border-radius: 3px;
  background: #c6f5ff;
  cursor: pointer;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #009cbd;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #009cbd;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #009cbd;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: rgb(30, 70, 104);
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]:focus::-ms-fill-lower {
  background: #009cbd;
}
input[type=range]::-ms-fill-upper {
  background: #009cbd;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]:focus::-ms-fill-upper {
  background: #009cbd;
}