
.Sidebar-Container {
    background: linear-gradient(0deg,#056,#009cbd);
    background-position: center center;
    background-size: cover;
    height: 100vh;
    position: absolute;
    width: 280px;
    z-index: 1000;
    
    &.hidden {
        display: none;
    }
}

.Sidebar-Wrapper {
    height: calc(100% - 80px);
    margin: 80px 0 0 0;
    width: 100%;
}

.Sidebar-Category {
    color: #fff;
    padding: 8px;
    text-transform: uppercase;
}

.Sidebar-Category-Label {
    border-top: 1px solid #fff;
    padding: 4px 0 4px 16px;
}

.Sidebar-Link {
    color: #fff;
    display: flex;
    font-size: larger;
    padding: 4px 0 4px 32px;

    & div {
        padding: 0 4px;
    }

    &.selected {
        background: #2e687d;
    }

    &:hover {
        background: #2e687d;
        color: #fff;
    }
}