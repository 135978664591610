
.Scrubber-Navigation-Container {
    height: 100%;
    overflow: hidden;
}

.Scrubber-Navigation-Wrapper {
    height: 100%;
    position: relative;
}

.Scrubber-Navigation-Events-Container {
    background: #ebebeb;
    border-radius: 8px;
    height: 100%;
    overflow: auto;
    padding: 8px;
    width: 100%;
}

.Scrubber-Navigation-Events-List {
    list-style: none;
    margin: 0;
    padding: 0;
}

.Scrubber-Navigation-Events-List-Item-Container {
    color: #000;
    cursor: pointer;
    margin: 3px 0;

    .disabled {
        background: transparent !important;
    }

    &.selected {
        background: #0000ff2b;
    }
}
.Scrubber-Navigation-Events-List-Item-Container:hover {
    background: #0000ff2b;

    .disabled {
        background: transparent !important;
    }
}

.Scrubber-Navigation-Events-List-Item-Wrapper {
    display: flex;
}

.Scrubber-Navigation-Events-List-Item-Timestamp {
    padding: 0 4px;
}

.Scrubber-Navigation-Events-List-Item-Color-Code {
    height: 16px;
    margin: auto 4px;
    padding: 0 4px;
    width: 18px;
}

.Scrubber-Navigation-Events-List-Item-Description {
    display: 'flex';
    padding: 0 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    span {
        color: #808080;
        margin: 0 4px;
    }

    span:nth-child(2) {
        color: black;
    }
}

.Scrubber-Navigation-Exports-Container {
    background: #ebebeb;
    border-radius: 8px;
    height: 100%;
    overflow: auto;
    width: 100%;
}

.Scrubber-Navigation-Exports-List {
    list-style: none;
    margin: 0;
    padding: 8px;
}

.Scrubber-Navigation-Export-Item-Container {
    background: #5195c5;
    border: 1px solid #000daf;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0px #000;
    margin: 4px 0;
    cursor: pointer;

    h6 {
        color: #000;
    }
    p {
        color: #000;
    }
    svg {
        color: #000;
    }
}

.Scrubber-Navigation-Export-Item-Wrapper {
    display: flex;
    justify-content: space-between;
    padding: 8px;

    & .Scrubber-Navigation-Export-Item-Details {

        & p {
            margin: 0;
            padding: 0
        }
    }
}

.Scrubber-Navigation-Plugin-Container {
    background: #053269;
    border: 2px solid #0b56af;
    border-radius: 8px;
    color: #000;
    height: 100%;
    overflow: auto;
    padding: 20% 0 0 0;
    width: 100%;
}

.Scrubber-Navigation-Plugin-Form {
    display: block;
    margin: auto;
    overflow: auto;

    .form-control {
        color: #000;
        background: #fff !important;
        transition: null !important;

        &:focus {
            color: #000;
            background: #fff !important;
        }
    }

    form {
        margin: auto;
        width: fit-content;
    }

    input {
        color: #000;
        background: #fff !important;
    }

    label {
        color: #fff;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
}
.Scrubber-Navigation-Plugin-Form::-webkit-scrollbar {
    display: none;
}

.Scrubber-Navigation-Plugin-Controls {
    display: flex;
    justify-content: center;

    button {
        cursor: pointer;
        font-size: x-small !important;
        margin: 0 4px;
        padding: 10.5px 8px !important;
        width: 72px !important;
    }
}

.Scrubber-Navigation-Login-Button {
    background: #008000;
    border: 0;
    border-radius: 8px;
    color: #fff;
    font-weight: bolder;

    &.disabled {
        background: #616161;
        cursor: default;
    }
}

.Scrubber-Navigation-Export-Choice-Container {
    background: #053269;
    border: 2px solid #0b56af;
    border-radius: 8px;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
}

.Scrubber-Navigation-Export-Choice-Wrapper {
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    button {
        cursor: pointer;
        padding: 8px 16px;
        margin: auto 4px;
    }
}

.Scrubber-Navigation-Export-Choice-Button {
    background: #008000;
    border: 0;
    border-radius: 8px;
    color: #fff;
    font-weight: bolder;

    &.disabled {
        background: #616161;
        cursor: default;
    }
}

.Scrubber-Navigation-Export-Container {
    background: #053269;
    border: 2px solid #0b56af;
    border-radius: 4px;
    height: 100%;
    overflow: auto;
    padding: 8px;
    position: absolute;
    top: 0;
    width: 100%;
}

.Scrubber-Navigation-Export-Form {
    height: 416px;
    overflow: auto;

    .form-control {
        color: #000;
        background: #fff !important;
        transition: null !important;

        &:focus {
            color: #000;
            background: #fff !important;
        }
    }

    input {
        color: #000;
        background: #fff !important;
    }
    select {
        color: #000;
        background: #fff !important;

        option {
            color: #000;
        }
    }
    textarea {
        color: #000;
        background: #fff !important;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
}
.Scrubber-Navigation-Export-Form::-webkit-scrollbar {
    display: none;
}

.Scrubber-Navigation-Export-Controls {
    display: flex;
    justify-content: flex-end;

    button {
        cursor: pointer;
        font-size: x-small !important;
        margin: 0 4px;
        padding: 10.5px 8px !important;
        width: 72px !important;
    }
}

.Scrubber-Navigation-Event-Container {
    background: #284161;
    border: 2px solid #0b56af;
    border-radius: 4px;
    height: 100%;
    overflow: auto;
    padding: 8px;
    position: absolute;
    top: 0;
    width: 100%;
}

.Scrubber-Navigation-Event-Form {
    height: 416px;
    overflow: auto;

    .form-control {
        color: #000;
        background: #fff !important;
        transition: null !important;

        &:focus {
            color: #000;
            background: #fff !important;
        }
    }

    input {
        color: #000;
        background: #fff !important;
    }
    select {
        color: #000;
        background: #fff !important;

        option {
            color: #000;
        }
    }
    textarea {
        color: #000;
        background: #fff !important;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
}
.Scrubber-Navigation-Event-Form::-webkit-scrollbar {
    display: none;
}

.Scrubber-Navigation-Event-Controls {
    display: flex;
    justify-content: flex-end;

    button {
        cursor: pointer;
        font-size: x-small !important;
        margin: 0 4px;
        padding: 10.5px 8px !important;
        width: 72px !important;
    }
}

.Scrubber-Navigation-Cancel-Button {
    background: #ff0000;
    border: 0;
    border-radius: 8px;
    color: #fff;
    font-weight: bolder;
}

.Scrubber-Navigation-Export-Button {
    background: #008000;
    border: 0;
    border-radius: 8px;
    color: #fff;
    font-weight: bolder;

    &.disabled {
        background: #616161;
        cursor: default;
    }
}

.Scrubber-Navigation-Export-Button {
    background: #008000;
    border: 0;
    border-radius: 8px;
    color: #fff;
    font-weight: bolder;

    &.disabled {
        background: #616161;
        cursor: default;
    }
}

.Scrubber-Navigation-EPG-Container {
    background: #ebebeb;
    border-radius: 8px;
    height: 100%;
    overflow: auto;
    padding: 8px;
    width: 100%;
}

.Scrubber-Navigation-EPG-List {
    list-style: none;
    margin: 0;
    padding: 0;
}

.Scrubber-Navigation-EPG-Program {
    color: #000;
    cursor: pointer;
    display: flex;

    .program-timestamp {
        color: #777777;
        margin: 0 8px;
    }

    .program-title {
        margin: 0 8px;
    }

    .program-future {
        color: #d20d66;
    }
}

.Scrubber-Navigation-EPG-Program:hover {
    background: #0000ff2b;
}

