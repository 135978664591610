
.Scrubber-Controls-Panel-Container {
    border-top: 1px solid #0b56af;
    padding: 8px;
    width: 100%;
}

.Scruber-Controls-Playback-Controls {
    position: relative;
    width: 100%;
}

.Scrubber-Controls-Seek-Controls {
    display: flex;
    flex-grow: 1;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 0 0 4px 0;
}

.Scrubber-Controls-Left-Container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0 8px 0 0;
    max-width: 320px;
}

.Scrubber-Control {
    background: #11b0ee;
    border: 0;
    border-radius: 8px;
    box-shadow: 3px 3px 2px 0 #000000;
    color: #fff;
    cursor: pointer;
    font-size: x-small;
    height: fit-content;
    margin: 4px 4px;
    padding: 12px 8px;
    width: 64px;

    &.disabled {
        background: #656565a3;
        cursor: default;
    }
}

.Scrubber-Controls-PlayPause-Container {
    height: 80px;
    margin: auto 0;
    width: 80px;

    button {
        background: #ed7812;
        box-shadow: 3px 3px 3px #000000d9;
        color: #fff;
        cursor: pointer;
        border-radius: 50%;
        height: 100%;
        padding: 8px;
        width: 100%;

        &.disabled {
            background: #616161;
            cursor: default;
        }
    }
}

.Scrubber-Controls-Right-Container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 0 0 8px;
    max-width: 320px;
}

.Scrubber-Controls-Channel-Select-In-Out-Container {
    display: flex;
    justify-content: space-between;
}

.Scrubber-Controls-Channel-Select-Container {
    padding: 0 4px;
    width: fit-content;
}

.Scrubber-Controls-Channel-Select-Wrapper {
    height: 100%;
    display: flex;

    // div { 
    //     margin-right: 20px;
    //     cursor: pointer;
    // }

    .Scrubber-Controls-ChildBtn {
        padding: 8px 10px;
        border-radius: 10px;
        font-weight: bold;
        font-size: 16px;
        height: 40px;
    }
}

.Scrubber-Controls-In-Out-Container {
    display: flex;
    justify-content: flex-end;
    width: fit-content;
}

.Scrubber-Date-Time-Picker-Container {
    margin: 16px 0 0 0;
}

.Scrubber-Date-Time-Picker-Wrapper {
    display: flex;
    width: 100%;

    * {
        margin: 0 4px;
    }

    button {
        cursor: pointer;
        font-size: x-small;
        padding: 10.5px 8px;
        width: 72px;
    }
}

.Scrubber-Time-Picker {
    background: #ccc;
    border: 0;
    border-radius: 4px;
    font-size: large;
    font-weight: bold;
    height: 48px;
    margin: -12px 8px 0 0;
    padding: 8px;
    width: 200px;
}

.Scrubber-Date-Picker {
    background: #ccc;
    border: 0;
    border-radius: 4px;
    font-size: large;
    font-weight: bold;
    height: 48px;
    margin: -12px 8px 0 0;
    padding: 8px;
    width: 200px;
}