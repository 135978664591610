.StatusWrapper{
  width: 100px;
  height: 40px;
  position: absolute;
  top: 10px;
  left: calc(50vw - 50px);
  border-radius: 10px;
  font-weight: bold;
  padding: 10px 5px;
  background-color: rgba(255, 0, 0, 0.5);
  z-index: 1000;
  text-align: center;
}
