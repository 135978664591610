
.Dashboard-Container {
    height: calc(100vh - 56px);
    margin: 0 24px 0 112px;
    overflow: hidden;
    padding: 80px 0 0 0;
    width: calc(100% - 136px);
}

.Dashboard-Wrapper {
    height: 100%;
    overflow: auto;
    padding: 8px;
    width: 100%;

    -ms-overflow-style: none;
    scrollbar-width: none;
}
.Dashboard-Wrapper::-webkit-scrollbar {
    display: none;
}