// div {
//   box-sizing: border-box;
// }

.EPG-Screen-Container {
  height: 100%;
}

.cntDiv{
  margin: 5px;
  height: 30px;
}

.linDiv{
  float: left;
}

.dateDiv{
  float: right;
  font-size: 20px;
  font-weight: bold;
}

.empty{
  background: rgba(255, 0, 0, 0.1);
}

.timetable {
  position: relative;
  color: #fff;
  background: #002149;
  height: calc(100% - 56px);
  overflow-y: scroll;

  &__scrollable {
    overflow-x: auto;
    overflow-y: visible;
  }

  &__captions {
    width: 210px;
    position: absolute;
    left: 0;
    top: 30px;
    background: #002e65;
    z-index: 10;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);

    &-item {
      border-bottom: 1px solid #ffffff40;
      cursor: pointer;
      display: flex;
      height: 80px;
      justify-content: space-between;
      line-height: 50px;
      padding: 8px;
      position: relative;
      text-align: center;

      img {
          margin: auto 4px auto 0;
          max-height: 80%;
          max-width: 80%;
      }

      p {
          color: #fff;
          font-size: small;
          font-weight: bold;
          margin: auto 0 auto 4px;
          padding: 0;
          white-space: nowrap;
          max-width: 128px;
          overflow: hidden;
          text-overflow: ellipsis;
      }
    }
  }

  &__container {
    padding-left: 210px;
    box-sizing: content-box;
    position: relative;
  }

  &__header {
    margin-left: -210px;
    position: relative;
    background: rgba(0, 156, 189, 0.2);
    z-index: 15;

    &-row {
      height: 30px;
      margin-left: 210px;
      white-space: nowrap;
      font-size: 0;
      padding-top: 5px;
    }

    &-cell {
      width: 300px;
      height: 100%;
      font-size: 14px;
      font-weight: bold;
      line-height: 25px;
      display: inline-block;
      position: relative;

      border-left: 1px solid #fff;
      padding-left: 10px;

      &:after {
        content: '';
        height: 5px;
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        pointer-events: none;
        border-left: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }

  &__body {
    position: relative;
    overflow: hidden;

    &-row {
      height: 80px;
      position: relative;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    &-cell {
      padding: 10px;
      height: 100%;
      position: absolute;
      top: 0;
      display: inline-block;
      overflow: hidden;
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      border-right: 1px solid rgba(255, 255, 255, 0.1);

      cursor: pointer;
      background: #002149;

      &_now {
        background: #002858;//rgba(0, 156, 189, 0.1); //rgba(255, 255, 255, 0.05);
      }
    }
  }

  &__broadcast {
    font-size: 16px;
    line-height: 18px;

    &-name {
      margin: 0 0 2px;
      max-height: 40px;
      overflow: hidden;
    }

    &-time {
      font-size: 14px;
      color: #aaa;
      white-space: nowrap;
    }
  }

  &__current {
    width: 3px;
    margin-left: 210px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    background: rgba(255, 0, 0, 0.5);
    transition: left 0.5s;

    // &:before {
    //   content: '';
    //   width: 3px;
    //   height: 25px;
    //   display: block;
    //   position: absolute;
    //   top: 0;
    //   left: -1px;
    //   border-radius: 2px;
    //   background: rgba(255, 0, 0, 0.7);
    // }
  }

  &__now-button {
    padding: 7px 10px;
    display: block;
    // position: absolute;
    // right: 20px;
    // bottom: 30px;
    color: #fff;
    line-height: 1;
    text-decoration: none;
    cursor: pointer;

    margin-top: 3px;

    transition: all 0.15s ease;
    box-shadow: none;
    color: #ffffff;
    border: none;
    
    background: #0087bd;
    background-image: linear-gradient(to bottom left, #0087bd, #00638a, #0087bd);
    background-size: 210% 210%;
    background-position: top right;
    background-color: #0087bd;

    border-radius: 5px;

    z-index: 1000;

    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
}

.EPG-Header-Controls-Control {
    margin: 0 2px;

    .btn {
        font-size: smaller;
        padding: 8px 12px;
    }

    @media screen and (min-device-width: 1200px) {
        margin: 0 4px;

        .btn {
            font-size: smaller;
            padding: 8px 16px;
        }
    }
}

.EPG-Header-Dialog-Date-Picker-Container {
    height: 320px;
    left: 48px;
    position: absolute;
    top: 128px;
    width: 320px;

    @media screen and (min-device-width: 1200px) {
        height: 320px;
        left: 128px;
        position: absolute;
        top: 128px;
        width: 320px;
    }
}