.LoadingWrapper{
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  text-align: center;
}

.LoadingIndicator{
  margin-top: 10%;
}