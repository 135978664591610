
.Dialog-Container {
    background: #0000008c;
    height: 100vh;
    left: 0;
    overflow: hidden;
    overflow-y: scroll;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 1100;

    -ms-overflow-style: none;
    scrollbar-width: none;
}

.Dialog-Container::-webkit-scrollbar {
    display: none;
}