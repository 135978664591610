
.Modal-Confirmation-Container {
    height: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 100%;
}

.Modal-Confirmation-Wrapper {
    aspect-ratio: 16 / 9;
    background: #071c33;
    border: 1px solid #fff;
    border-radius: 8px;
    box-shadow: 0 0 8px 0 #ffffff85;
    display: flex;
    height: fit-content;
    justify-content: center;
    margin: auto;
    overflow: scroll;
    padding: 64px;
    position: relative;

    -ms-overflow-style: none;
    scrollbar-width: none;
}
.Modal-Confirmation-Wrapper::-webkit-scrollbar {
    display: none;
}

.Modal-Confirmation-Content {
    margin: auto;
}

.Modal-Confirmation-Controls-Container {
    bottom: 0;
    position: absolute;
    right: 0;
}

.Modal-Confirmation-Controls-Wrapper {
    padding: 8px;
}