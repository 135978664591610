#root{
    width: 100%;
    height: 100%;
}

.topNotification{
    width: 50%;
    margin: 0 auto;
}

.adminOnly{
    background: rgba(255, 0, 0, 0.3);
    // background: -moz-linear-gradient(90deg, rgba(255, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 90%);
    // background: -webkit-linear-gradient(90deg, rgba(255, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 90%);
    // background: -o-linear-gradient(90deg, rgba(255, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 90%);
    // background: -ms-linear-gradient(90deg, rgba(255, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 90%);
    // background: linear-gradient(180deg, rgba(255, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 90%);
}

.navbar.navbar-transparent{
    background: #002149 !important;
}

.centerText{
    text-align: center;
}

.card-white .card-header .card-title {
    margin-left: 10px !important;
}

.blue-row{
    // background-color: #009cbd;
    background-color: rgba(0, 157, 189, 0.25);
}

.rounded-row{
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1);
}

.versionNo{
    font-size: 11px;
    text-align: right;
}

@media screen and (max-width: 1399px) {
    .largeDisplay{
        display: none !important;
    }
}