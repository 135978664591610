
// =============================== Cookie-Banner ===============================
.Cookie-Banner-Container {
    background: #00000099;
    bottom: 0;
    color: #fff;
    display: flex;
    font-size: 0.9rem;
    justify-content: space-between;
    max-height: 64px;
    overflow: hidden;
    padding: 0 16px 0 280px;
    position: fixed;
    width: 100%;
    z-index: 10000;

    p {
        margin: 0;
        padding: 16px 8px;
    }
}

.Cookie-Banner-Controls {
    background: transparent;
    display: flex;
    margin-right: 80px;
    padding: 4px 0;

    div {
        border: 1px solid #fff;
        border-radius: 4px;
        cursor: pointer;
        padding: 8px;
        margin: 8px;
    }
    div:hover {
        background: #fff;
        color: #000;
    }

    #accept {
        background: #ffffffde;
        color: #000;
        cursor: pointer;
    }
    #accept:hover {
        background: #fff;
        color: #000;
    }
}

.Cookie-Banner-Link {
    color: #009cbd;
}
.Cookie-Banner-Link:hover {
    color: #009cbd;
}