
// ========================================= Detection =========================================
.Detection-Container {
    height: 100vh;
    padding: 96px 0 64px 0;
    width: 100%;
}

// ========================================= Detection-Player =========================================
.Detection-Player-Container {
    height: calc(100% - 64px);
    padding-top: 64px;
}

.Detection-Player {
    display: flex;
    justify-content: center;
}

.Detection-Controls {
    display: flex;
    justify-content: space-evenly;
    margin: 16px 0 0 0;
}

// ========================================= Detection-Search =========================================
.Detection-Search-Form {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 50%;
    
    span {
        margin: auto 0;
    }

    input {
        border: 0;
        color: #000;
        filter: invert(1);
        height: 100%;
        margin: auto 0;
    }
    .form-control:focus {
        color: #000 !important;
    }
}

.Detection-Search-Result-Container {
    margin: 24px auto 0 auto;
    width: 80%;

    .MuiPaper-root {
        padding: 8px
    }
}

.Detection-Search-Result-List {
    background: #fff;
    border-radius: 4px;
    list-style: none;
    margin: 0;
    max-height: 640px;
    overflow-y: auto;
    padding: 0;

    .Detection-Search-Result-List-Item:first-child {
        height: 56px;
        margin: auto 0;

        div {
            font-weight: bold;
        }
    }

    .Detection-Search-Result-List-Item:last-child {
        border-bottom: 0;
    }
}

.Detection-Search-Result-List-Item {
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    justify-content: space-around;
    padding: 8px;

    div {
        color: #000;
        margin: auto 0;
        text-align: left;
        width: 50%;
    }

    img {
        height: 40px;
        margin: -8px 16px -8px 0;
        width: 40px;
    }
}