.full-page > .content{
  padding-top: 80px !important;
}

.sidebar-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.sidebar-wrapper::-webkit-scrollbar {
    display: none;
}