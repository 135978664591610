.Cell {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;
}

.EPG-Container {
    height: calc(100vh - 28px);
    overflow: hidden;
    padding: 64px 24px 0 24px;
    width: 100%;
}

.EPG-Wrapper {
    height: 100%;
}

.EPG-Live-Player-Container {
    background: #000;
    border: 1px solid #5f5f5f;
    border-radius: 8px;
    box-shadow: 0 0 8px 1px #5f5f5f;
    display: flex;
    height: calc(100% - 48px);
    justify-content: center;
    margin: 24px 40px 24px 80px;
    overflow: auto;
    width: calc(100% - 120px);

    -ms-overflow-style: none;
    scrollbar-width: none;
}
.EPG-Live-Player-Container::-webkit-scrollbar {
    display: none;
}

.EPG-Live-Player-Wrapper {
    display: flex;
    height: calc(100% - 32px);
    margin: 16px 96px 16px 96px;
    min-height: 352px;
    overflow: visible;
    padding: 0 4px;
    width: calc(92% - 40px - 128px);
}

.EPG-Live-Player {
    height: fit-content;
    flex-grow: 1;
    margin: auto 0;
    width: 100%;
}

.EPG-Live-Player-Controls {
    height: 100%;
    padding: 8px 8px 8px 0px;

    .Dialog-Button {
        background: #ffffffdb;
        border-radius: 50%;
        color: #000;
        cursor: pointer;
        margin: 0 0 16px 8px;
        padding: 4px;
    }
    .Dialog-Button:hover {
        background: #fff;
        box-shadow: 0 0 6px 0 #fff;
    }
}