
.Breakout-Container {
    display: flex;
    height: calc(100vh - 80px);
    margin: auto 0;
    overflow: auto;
    padding: 80px 0 0 0;
    position: relative;
    width: 100%;

    @media screen and (min-width: 1200px) {
        height: calc(100vh - 80px);
        margin: auto 24px auto 112px;
        overflow: auto;
        padding: 80px 0 0 0;
        width: calc(100% - 136px);
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
}
.Breakout-Container::-webkit-scrollbar {
    display: none;
}

.Breakout-HLS-Player-Container {
    display: flex;
    height: 100%;
    margin: 0 auto;
    overflow: visible;
    width: 80%;
}

.Breakout-HLS-Player-Wrapper {
    height: 100%;
    margin: auto 0;
    width: calc(100% - 56px);
}

.Breakout-HLS-Player-Peak-Container {
    height: calc(100% - 56px);
}

.Breakout-HLS-Player-Peak-Meter {
    display: flex;
    height: 100%;
    width: 56px;
}

.Breakout-Controls-Container {
    position: absolute;
    top: 96px;
    right: 96px;
}

.Breakout-Controls-Wrapper {
    height: 100%;
    padding: 8px 8px 8px 0px;
}

.Breakout-Control {
    background: #ffffffdb;
    border-radius: 50%;
    color: #000;
    cursor: pointer;
    margin: 0 0 16px 8px;
    padding: 4px;
}
.Breakout-Control:hover {
    background: #fff;
    box-shadow: 0 0 6px 0 #fff;
}