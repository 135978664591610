
.Scrubber-Container {
    height: calc(100vh - 28px);
    overflow: hidden;
    padding: 64px 0 0 0;
    position: relative;
    width: 100%;
}

.Scrubber-Wrapper {
    height: 100%;
    overflow: auto;
    padding: 0 8px;
    position: relative;
    width: 100%;

    -ms-overflow-style: none;
    scrollbar-width: none;
}
.Scrubber-Wrapper::-webkit-scrollbar {
    display: none;
}

.Scrubber-Top {
    // height: calc(100% - 270px);
    height: 70%;
    position: absolute;
    top: 0;
    width: calc(100% - 16px);
}
.Scrubber-Bottom {
    height: 30%;
    bottom: 0;
    // height: 250px;
    // bottom: 20px;
    position: absolute;
    width: calc(100% - 16px);
}