  // Core
@import "../../../assets/scss/black-react/custom/functions";
@import "../../../assets/scss/black-react/bootstrap/functions";
@import '../../../assets/scss/black-react/custom/variables';

$head-font-size: 18px;
$text-font-size: 16px;
$lable-font-size: 16px;
$text-readonly-font-size: 17px;

$primary: #009cbd; // rgb(0, 156, 189);
$primaryLight: #00c9f4;
$secondary: #ffa300; //rgb(255, 163, 0);
$secondaryLight: #ffcc00;

$mainBackground: #001024;
$secondBackgound: #002149;
$thirdBackgound: #002d64;
$fourthBackground: #002b63;

$white: #ffffff;
$black: #000000;

$textColor: #292929;
$textColorLight: #fff;

$boxShadow: "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)";
$primaryBoxShadow: "0 12px 20px -10px rgba(0, 156, 189, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 156, 189, 0.2)";
$secondaryBoxShadow: "0 12px 20px -10px rgba(255, 163, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 163, 0, 0.2)";
  
$panelBoxShadow: "0 2px 10px 0px rgba(0, 156, 189, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 156, 189, 0.2)";

.evo-toolbar{
  height: 100%;
  overflow: auto;
  padding: 20px;
}
.evo-toolbar::-webkit-scrollbar {
    display: none;
}

.evo-toolbar-button{
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}

.evo-list-editbtns{
  display: flex;
  max-width: 100px;
  float: right;
  > button {
    margin-right: 5px;
  }
}

.MuiTable-root{
  border-color: $white !important;
}
.MuiPaper-root{
  background: #001024 !important;
}
.MuiTableCell-root{
  background: transparent !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.evo-grid-images{
  max-width: 50px;
}

.evo-cards {
  width: 100%;
  min-height: 200px;
}

.evo-card{
  padding: 10px 0;
}
.evo-card-btn {
  float: right;
}

.evo-browse-btn{
  float: right;
}
.evo-card-container {
  margin-top: 10px;
  margin-bottom: 20px;
}

.evo-cards-pagination{
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.evol-fld{
	input, textarea, select{
		font-size: $text-font-size;
	}

  select {
    option {
      color: #000000;
    }
  }
}

// Tags
.ReactTags__tagInputField{
  display: block;
  width: 100%;
  height: calc(2.0625rem + 2px);
  padding: 0.375rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #282828;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-top: 10px;
}

.ReactTags__tag{
  font-size: 20px;
  background: $fourthBackground;
  padding: 5px;
  border-radius: 3px;
  margin-right: 5px;
}
.ReactTags__tag:hover{
  background: $primary;
}

.ReactTags__remove{
  font-size: 25px;
  font-weight: bold;
  padding: 0px 0px 0px 7px;
}

// Browse

.evol-pnls{
  display: flex;
  flex-wrap: wrap;
}

.evo-page-title{
  text-align: left;
  padding-left: 20px;
}

.evo-one {
  background-color: $mainBackground;
  border-radius: 10px;
  padding: 10px;
  box-shadow: $boxShadow;
}

.evol-pnl{
  // background-color: $secondBackgound;
  border-radius: 5px;
  padding: 10px;
  flex-grow: 1;
  box-sizing: border-box;
  min-width: 150px;
  margin: 10px;
  // box-shadow: $panelBoxShadow;
}

.evol-fset {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 15px 10px 0px;
}

.evol-fld{
  box-sizing: border-box;
  flex-grow: 1;
  margin-bottom: 10px;
  min-width: 50px;
  padding-left: 5px;
}

.evol-field-label{
  font-size: $lable-font-size;
  font-weight: bold;
}

.evo-rdonly{
  min-height: 30px;
  padding: 5px 0px 0px 5px;
  font-size: $text-readonly-font-size;
}

.evo-rdonly.text{
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}

.evo-rdonly .tags{
  > ul {
    > li {
      list-style-type: none;
    }
  }
}

.uppy-DragDrop-container{
  background-color: $fourthBackground;
}

.uppy-DragDrop-label, .uppy-DragDrop-arrow{
  font-size: $head-font-size;
  text-align: center;
  width: 100%;
}

.uppy-DragDrop-dragText{
  color: $primary;
}

.uppy-DragDrop-note{
  display: block;
  padding: 25%;
}

.text.evo-rdonly{
  //background-color: $fourthBackground;
  word-break: break-all;
  overflow: hidden;
}

.evo-browse-btn.save{
  color:$white;
}

.evol-remove > a{
  color: $secondary;
}

.evol-err-msg{
  color: $secondaryLight;
  font-size: $head-font-size;
  font-weight: bold;
}

.img-thumbnail{
  //max-height: 200px;
}

.panel {
  margin-bottom: 10px;
}

.panel-heading {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 10px 15px;
  border-bottom: 1px solid $primary;
}

.panel-title{
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: bold;
  font-size: $head-font-size;
}

.modal-content{
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.ReactModal__Overlay{
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.evol-colorIndicator{
  width: 100px;
  height: 40px;
  border-radius: 10px;
}
.evol-colorIndicatorTable{
  width: 50px;
  height: 40px;
  border-radius: 10px;
}

.evol-addTags{
  margin: 20px 0px 0px;
  width: 100%;
  //background-color: #333;
  border: 1px solid #2b3553;
  border-radius: 5px;
}

.EVOCardActionArea{
  width: 100%;
}

.EVOCardContent{
  padding-top: 30px !important;
}

.EVOCardColor{
  box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.8);
  z-index: 1;
  position: absolute;
}

.evoCheckbox{
  display: inline-flex;
  p {
    margin-left: 5px;
  }
}

.sketch-picker {
  border: 1px solid #2b3553;
  background-color: transparent !important;
  > div > div > div > span {
    color: #ffffff !important;
  }
  .flexbox-fix{
    background-color: transparent !important;
  }
}

.evoui-html-wrapper{

}
.evoui-html-sample{
  width: 100%;
  min-height: 250px;
  max-height: 500px;
  padding: 5px;
  background-color: transparent;
  color: white;
  border-radius: 10px;
  border: 1px solid #2b3553;
  margin: 5px;
}
// .rdw-editor-toolbar[aria-hidden="true"]{
//   display: none !important;
//}
.evoui-html-editor{
  // min-height: 500px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.6);

  background-position: center;
  background-size: cover;

  overflow: hidden !important;

  //position: absolute;
  //z-index: 2;
}
.evoui-html-editor.hideMe{
  display: none !important;
}
.evoui-html-background {
  background-size: contain;
  background-repeat: no-repeat;

  //position: absolute;
  //z-index: 1;
}

.btn-show-code{
  color: black;
  border: 1px solid rgba(255, 0, 0, 0.5) !important;
  padding: 2px;
}

.tag-remove{
  color: black !important;
  font-size: 120%;
  font-weight: bold;
}

.transcodeProgress{
  text-align: center;
  font-size: 12px;
}

.transcodeProgressPerc{
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  &.red {
    color: red;
  }
  &.orange {
    color: rgb(255, 102, 0);
  }
  &.green {
    color: rgb(68, 128, 0);
  }
}

@media (min-width: 576px) {
  .evoui-html{
    width: 400px;
    height: 400px;
  }
  .evoui-html-editor{
    width: 400px;
    height: 225px !important;
  }
  // .evoui-html-background {
  //   width: 400px;
  // }
}
@media (min-width: 768px) {
  .evoui-html{
    width: 600px;
    height: 500px;
  }
  .evoui-html-editor{
    width: 600px;
    height: 337.5px !important;
  }
  // .evoui-html-background {
  //   width: 600px;
  // }
}
@media (min-width: 992px) {
  .evoui-html{
    width: 800px;
    height: 600px;
  }
  .evoui-html-editor{
    width: 800px;
    height: 450px !important;
  }
  // .evoui-html-background {
  //   width: 800px;
  // }
}
@media (min-width: 1200px) {
  //800 x 450
  .evoui-html{
    width: 920px;
    height: 650px;
  }
  .evoui-html-editor{
    width: 920px;
    height: 517.5px !important;
  }
  // .evoui-html-background {
  //   width: 920px;
  // }
}