
.Channel-Container {
    border-radius: 4px;
    height: 100%;
    // max-height: 256px;
    // max-width: 360px;
    min-height: 150px;
    min-width: 250px;
    width: 100%;

    &.failed {
        box-shadow: 0 0 4px 2px #ff0000de;
    }
}

.Channel-Wrapper {
    border-radius: 4px;
    background: #fff;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.Channel-Image {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}

.channelheader {
    position: absolute;
    width: 100%;
    z-index: 2;

    color: #000;
    text-align: center;
    font-weight: bold;
}

.Channel-Image-Audio-Only {
    top: 20px;
    left: 0;
    position: absolute;
    width: 100%;
    height: 80px;
    z-index: 2;
}
.Channel-Details-Container {
    background: #0000007d;
    bottom: 0;
    display: flex;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 3;

    &.failed {
        background: #a00000d1;
    }
}

.Channel-Details-Description-Container {
    color: #fff;
    flex-grow: 3;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: 0 0 2px #000;
    white-space: nowrap;
}

.Channel-Details-Description-Identity {
    font-size: larger;
    font-weight: 900;
    margin: 0;
    overflow: hidden;
    padding: 8px 8px 4px 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Channel-Details-Description-Feed {
    font-weight: 600;
    margin: 0;
    padding: 4px 8px 8px 8px;
}

.Channel-Details-Actions-Container {
    display: flex;
    flex-grow: 1;
    height: 100%;
    justify-content: flex-end;
    margin: auto 0;
    padding: 0 4px;
}

.Channel-Details-Action-Container {
    height: 100%;
    margin: 0 4px;
}

.Channel-Details-Action {
    border-radius: 50%;
    box-shadow: 0 0 4px 0px #00000080;
    cursor: pointer;
    height: 32px;
    padding: 4px;
    width: 32px;

    &.restart {
        background: #ff0076;
        color: #fff;
    }

    &.play {
        background: #fff;
        color: #000;
    }
}