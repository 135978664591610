.childTable {
  padding: 10px !important;
   > div{
    .MuiToolbar-root {
      display: none;
    }
  }
}

.MuiTableCell-head{
  font-weight: bold !important;
}