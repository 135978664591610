
.Tabs-Panel-Container {
    overflow: hidden;
    width: 100%;
}

.Tabs-Panel-Wrapper {
    height: 100%;
    padding: 8px 0;
    width: 100%;

    -ms-overflow-style: none;
    scrollbar-width: none;
}
.Scrubber-Navigation-Wrapper::-webkit-scrollbar {
    display: none;
}