
.Scrubber-Panel-Timeline-Programs-Container {
    display: flex;
    height: 100%;
    margin: 0 80px 0 80px;
    overflow: hidden;
    position: absolute;
    width: calc(100% - 160px);
}

.Scrubber-Panel-Timeline-Programs-Wrapper {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.Scrubber-Panel-Timeline-Program {
    background: #0d8cbf;
    border-radius: 8px;
    box-shadow: 2px 2px 3px 0 black;
    color: #000;
    overflow: hidden;
    padding: 4px 0 4px 8px;
    position: absolute;
    text-align: left;
    text-overflow: ellipsis;
    top: 50%;
    white-space: nowrap;

    p {
        font-size: x-small;
    }
}

.child {
    background: rgba(36,181,114,0.6);
}