
.Tabs-Container {
    height: 100%;
    width: 100%;
}

.Tabs-List {
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
}

.Tabs-List-Item {
    cursor: pointer;
    float: left;
    font-size: medium;
    font-weight: 600;
    min-width: 124px;
    padding: 8px;
    text-align: center;
}
.Tabs-List-Item-Selected {
    border-bottom: 2px solid red;
}