
.Modal-HLS-Player-Container {
    height: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 100%;
}

.Modal-HLS-Player-Wrapper {
    aspect-ratio: 16 / 9;
    background: #071c33;
    border: 1px solid #fff;
    border-radius: 8px;
    box-shadow: 0 0 8px 0 #ffffff85;
    display: flex;
    justify-content: center;
    margin: 2%;
    overflow: scroll;
    padding: 64px;
    position: relative;

    -ms-overflow-style: none;
    scrollbar-width: none;
}
.Modal-HLS-Player-Wrapper::-webkit-scrollbar {
    display: none;
}

.Modal-HLS-Player-Controls-Container {
    position: absolute;
    top: 0;
    right: 0;
}

.Modal-HLS-Player-Controls-Wrapper {
    height: 100%;
    padding: 8px 8px 8px 0px;

    .Modal-HLS-Player-Control {
        background: #ffffffdb;
        border-radius: 50%;
        color: #000;
        cursor: pointer;
        margin: 0 0 16px 8px;
        padding: 4px;
    }
    .Modal-HLS-Player-Control:hover {
        background: #fff;
        box-shadow: 0 0 6px 0 #fff;
    }
}