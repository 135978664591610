
.Scrubber-Panel-Timeline-Events-Container {
    display: flex;
    height: 16px;
    margin: 0 80px 0 80px;
    position: absolute;
    top: 28%;
    width: calc(100% - 160px);
}

.Scrubber-Panel-Timeline-Events-Wrapper {
    overflow: hidden;
    width: 100%;
}

.Scrubber-Panel-Timeline-Event {
    box-shadow: 1px 1px 2px 0 black;
    height: 100%;
    position: absolute;

    & p{
        display: none;
    }
}