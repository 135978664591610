
.Live-Container {
    height: calc(100vh - 80px);
    margin: auto 0;
    overflow: auto;
    padding: 80px 0 0 0;
    width: 100%;

    @media screen and (min-width: 1200px) {
        height: calc(100vh - 80px);
        margin: auto 24px auto 112px;
        overflow: auto;
        padding: 80px 0 0 0;
        width: calc(100% - 136px);
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
}
.Live-Container::-webkit-scrollbar {
    display: none;
}

.Live-Player-Container {
    height: 100%;
    margin: 0 auto;
    overflow: visible;
    width: 74%;
}

.Live-Player-Wrapper {
    height: 100%;
    margin: auto 0;
    width: 100%;
}

.Live-Player-Controls-Container {
    display: flex;
    justify-content: center;
    padding: 8px;
}